import React from "react"
import {
  BlogCard,
  Container,
  BlogImage,
  BlogDescription,
  BlogTitle,
  BlogReadTime,
} from "../../../ToolsStyles/BlogSectionStyled"

import BlogImage1 from "../../Assets/PDFToPng.avif"
import BlogImage2 from "../../Assets/ResizeImage.avif"
import BlogImage3 from "../../Assets/CropImage.avif"
import BlogImage4 from "../../Assets/Watermark.avif"

import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { navigate } from "gatsby"
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1174 },
    items: 4,
  },
  laptop: {
    breakpoint: { max: 1174, min: 900 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 900, min: 400 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 400, min: 0 },
    items: 1,
  },
}
const BlogSection = () => {
  return (
    <Container>
      <Carousel responsive={responsive}>
        <BlogCard
          onClick={() => navigate("/blog/guides/how-to-turn-a-pdf-into-a-png/")}
        >
          <BlogImage src={BlogImage1} alt="blog-image" />
          <BlogTitle>
            How to Turn a PDF into a PNG File for Free (2023)
          </BlogTitle>
          <BlogDescription>
            Learn how to turn a PDF into a PNG with step-by-step instructions!
            Adobe Acrobat, PDF Pro, and for free — we have the most popular and
            reliable methods.
          </BlogDescription>
          <BlogReadTime>3 min read</BlogReadTime>
        </BlogCard>
        <BlogCard
          onClick={() => navigate("/blog/guides/how-to-resize-a-pdf-image/")}
        >
          <BlogImage src={BlogImage2} alt="blog-image" />
          <BlogTitle>How to Resize a PDF Image Instantly in 2023</BlogTitle>
          <BlogDescription>
            Learn how to instantly resize a PDF image in 2023! We've listed the
            best methods for Windows, Mac, and online with step-by-step
            instructions.
          </BlogDescription>
          <BlogReadTime>3 min read</BlogReadTime>
        </BlogCard>
        <BlogCard onClick={() => navigate("/blog/guides/how-to-crop-a-pdf/")}>
          <BlogImage src={BlogImage3} alt="blog-image" />
          <BlogTitle>How to Crop a PDF: 2023 Guide</BlogTitle>
          <BlogDescription>
            Learn how to crop PDF pages with PDF Pro, Windows, Mac, Microsoft
            Word, LibreOffice, Google Drive/Google Docs, Preview/Mac, PDF
            Expert. Free and paid tools.
          </BlogDescription>
          <BlogReadTime>4 min read</BlogReadTime>
        </BlogCard>
        <BlogCard
          onClick={() => navigate("/blog/guides/add-watermark-to-pdf/")}
        >
          <BlogImage src={BlogImage4} alt="blog-image" />
          <BlogTitle>Add Watermark to PDF</BlogTitle>
          <BlogDescription>
            Easily and quickly add watermarks to PDF on Windows. Step-by-step
            instructions on how to insert, edit, and delete watermarks on PDF
            files.
          </BlogDescription>
          <BlogReadTime>3 min read</BlogReadTime>
        </BlogCard>
      </Carousel>
    </Container>
  )
}

export default BlogSection
