import React from "react"
import {
  Container,
  Paragraph,
  Title,
  TitleHolder,
} from "../../../ToolsStyles/RelatedGuidesStyled"

const RelatedGuides = () => {
  return (
    <Container>
      <TitleHolder>
        <Title>Related Guides</Title>
        <Paragraph>Here are some helpful related guides.</Paragraph>
      </TitleHolder>
    </Container>
  )
}

export default RelatedGuides
