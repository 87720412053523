import React, { useState, useEffect, useRef, useContext } from "react"
import { GlobalAuthContext } from "../../../Contexts/GlobalAuthContext"
import styled from "styled-components"
// import { Document, Page } from "react-pdf"
// import pdfjs from "pdfjs-dist"

import displayPdfSyncPreview from "../helpers/displayPdf-Preview"
import { FileUploader } from "react-drag-drop-files"
import TryPdf from "../../shared/TryPdf"
import ArrowRight from "../../../icons/tools/arrow-right.svg"
import ErrorMessage from "../ErrorMessage"
import UploadIcon from "../../../icons/tools/upload.svg"
import CompleteIcon from "../../../icons/tools/mergedIcon.svg"
import GDPRwarning from "../GlobalToolComponents/GDPRwarning"
import Title from "../GlobalToolComponents/ToolH2tag"
import PNGIcon from "../../../icons/tools/toPNG/ToPNG.svg"
import ArrowTry from "../../../icons/tools/tryPdfArrow.svg"
import RestartIcon from "../../../icons/tools/Restart.svg"
import PrivacyQuestion from "../../../icons/tools/PrivacyQuestion.svg"
import { formatBytes } from "../helpers/helpers"
import Warning from "../Warning"
import { toPNG } from "./Actions"

import {
  DownloadScreen,
  CanvasStyle,
  LoadingScreen,
  Pdfpreview,
} from "../Styles"
import Disclaimer from "../GlobalToolComponents/Disclaimer"
import UsefulTools from "./Landing/UsefulTools/UsefulTools"
const LoadingCircle = styled.div`
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid rgba(0, 0, 0, 0);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
const FileUploaderStyle = styled.div`
  margin: auto;
  max-width: 532px;
  width: 100%;
  margin-top: 60px;

  .drop_zone {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed red;
    width: 100%;
    min-height: 374px;
    background: #f5f6fc;
    border: 1px dashed #6361ff;
    border-radius: 2px;
    margin: 8px 0;
  }

  :hover {
    max-width: 548px;
    .drop_zone {
      min-height: 390px;
      background: #ebeeff;
      margin: 0;
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      font-size: 18px;
      line-height: 28px;
      color: #425466;
      text-align: center;
      margin-top: 25px;
      margin-bottom: 8px;
      :after {
        content: "Drag and drop your PDF file here";
      }
      @media (max-width: 744px) {
        :after {
          content: "Tap to upload PDF file here";
        }
      }
    }
    .info {
      font-size: 13px;
      line-height: 22px;
      text-align: center;
      color: #6361ff;
      text-transform: capitalize;

      :after {
        content: "PDF files smaller than 20MB";
      }
      @media (max-width: 744px) {
        :after {
          content: "PDF files smaller than 20MB";
        }
      }
    }

    button {
      all: unset;
      margin-top: 40px;
      font-size: 17px;
      line-height: 20px;
      color: #ffffff;
      padding: 12px 24px;
      gap: 12px;
      background: #6361ff;
      border-radius: 8px;
      cursor: pointer;
      @media (max-width: 744px) {
        display: none;
      }
    }
  }
`
const Offline = styled.div`
  background: #e3e8ee;
  border-radius: 2px;
  width: 73px;
  height: 26px;
  font-size: 13px;
  line-height: 22px;
  text-transform: uppercase;
  color: #6a7485;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;

  :hover {
    .warning {
      opacity: 1;
    }
  }
`

const ToPdf = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: #333333;
  a {
    color: #6361ff;
    text-decoration: none;
  }

  .desktop {
    display: inline-block;
    @media (max-width: 600px) {
      display: none;
    }
  }
`

const Restart = styled(ToPdf)`
  margin-top: auto;
  display: flex;
  margin-bottom: 32px;
  margin-top: 32px;
`
const CtaPopup = styled.div`
  color: #6361ff;
  text-decoration: none;
  display: inline;
  cursor: pointer;
  white-space: nowrap;
`

const CtaRestat = styled(CtaPopup)`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin: 0 8px;
  }
`

const SingleFileUploadStyle = styled.div`
  min-width: 205px;
  min-height: 283px;
  width: max-content;
  margin: 0;
  margin-bottom: 90px;
  display: inline-block;

  .drop_zone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 283px;
    background: #f5f6fc;
    border: 1px dashed #6361ff;
    border-radius: 2px;
    cursor: pointer;

    :hover {
      background: #ebeeff;
    }
  }
  .column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .title {
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      color: #6361ff;
      font-weight: 800;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .info {
      max-width: 97px;
      font-size: 13px;
      line-height: 15px;
      text-align: center;
      color: #6361ff;
      white-space: pre-wrap;
    }
  }
`

const Pdfholder = styled.div`
  min-height: 283px;
  min-height: 400px;
  display: flex;
  align-items: center;
  gap: 32px;
  padding: 0 8px;

  @media (max-width: 400px) {
    min-height: auto;
  }
`

const ExportButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 12px;
  min-width: 170px;
  white-space: nowrap;
  transition: background-color 1s;
  height: 48px;
  background: #6361ff;
  border-radius: 8px;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;

  svg {
    min-width: 24px;
    min-width: 24px;
  }

  @media (max-width: 440px) {
    width: 90%;
  }
  @media (max-width: 700px) {
    width: 100%;
    max-width: 322px;
    margin-top: 40px;
  }
  @media (max-width: 400px) {
    margin-right: 40px;
    margin-left: 40px;

    width: 75%;
    min-width: 228px;
  }
`

const SecondScreen = styled.div`
  /* ${props => props.hide && "display: none;"} */
  /* transform: translateY(124px); */
`

const ButtonHolder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 34px;
  margin-top: 40px;

  overflow: visible;

  @media (max-width: 440px) {
    padding-top: 32px;
    flex-direction: column;
    margin: auto;
    align-items: center;
  }
`

const Message = styled.div`
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #425466;
  margin-bottom: 8px;
  @media (max-width: 440px) {
    margin-bottom: 0;
    display: none;
  }
`
const Loading = styled.div`
  font-size: 21px;
  line-height: 25px;
  text-align: center;
  color: #333333;
  margin-bottom: 24px;
`

const BarBack = styled.div`
  width: 320px;
  height: 8px;
  background: #d9d9d9;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
`

const BarButton = styled.div`
  ${props => `width: ${320 * props.percent}px;`}
  background: #6361FF;
  height: 8px;

  border-radius: 4px;
`
const Percent = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  color: #8d9092;
  margin-top: 8px;
`
const DownloadTitle = styled.div`
  font-size: 38px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #333333;
  margin: 24px 0;
  @media (max-width: 744px) {
    font-size: 32px;
    line-height: 32px;
  }
`

const DownloadMessage = styled.div`
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #425466;
  @media (max-width: 744px) {
    font-size: 16px;
    line-height: 26px;
  }
`
const DownloadButton = styled.div`
  min-width: 156px;
  height: 48px;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  background: #6361ff;
  border-radius: 8px;
  margin-top: 40px;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
  @media (max-width: 440px) {
    width: 100%;
  }
`

const ErrorMessageHolder = styled.div`
  background: red;
  height: 100%;
  width: 0px;
  position: absolute;
  left: 50%;
  bottom: 0;
  padding-bottom: 258px;
  padding-top: 258px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 744px) {
    justify-content: flex-start;
  }
`

const RestartSecond = styled(Restart)`
  justify-content: center;
  margin-top: 32px;
  position: absolute;
  bottom: 0;
  width: 90vw;
  max-width: 1080px;
`
const Info = styled.div`
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #425466;
  max-height: 0;
  overflow: visible;
  margin-top: 32px;

  @media (max-width: 440px) {
    /* display: none; */
  }
`

function ToPNGTool() {
  const [file, setFile] = useState(null)
  const [errorFiles, setErrorFiles] = useState([])
  const [repository, setRepository] = useState([])
  const [fileRendered, setFileRendered] = useState(0)
  const [pageNum, setPageNum] = useState(null)
  const [filesSize, setFilesSize] = useState(0)

  const [download, setDownload] = useState(null)
  const [errors, setErrors] = useState([])
  const [convert, setConvert] = useState(false)

  const [loading, setLoading] = useState(false)

  const pdfHolder = useRef(null)
  const pdfPreview = useRef(null)

  useEffect(() => {
    if (pdfHolder?.current) {
      function detectScrollability() {
        if (pdfPreview.current?.scrollWidth) {
          if (pdfPreview.current.scrollWidth > pdfPreview.current.clientWidth) {
            pdfPreview.current.style.justifyContent = "normal"
          } else {
            pdfPreview.current.style.justifyContent = "center"
          }
        }
      }

      window.addEventListener("resize", () => {
        detectScrollability()
      })
      const observer = new MutationObserver(detectScrollability)

      detectScrollability()
      observer.observe(pdfPreview.current, {
        attributes: true,
        childList: true,
        subtree: true,
      })
    }
  }, [pdfHolder.current, pdfPreview.current?.scrollWidth])

  const { setScroll } = useContext(GlobalAuthContext)

  const [myPopUp, setMyPopUp] = useState("hide")
  function closePopup() {
    setMyPopUp("hide")
    setScroll("hide")
  }
  //open popup function
  function openPopup(e) {
    setMyPopUp("show")
    setScroll("show")
  }

  function restartTool(e) {
    window.location.reload()
  }

  useEffect(() => {
    if (fileRendered == 1) {
      setFileRendered(0)
      setLoading(false)
      console.log(errorFiles)
      if (errorFiles.length == 0) {
        setRepository([file])
        setErrorFiles([])
        setConvert(true)
      } else {
        setFile(null)
        setErrorFiles([])
      }
    }
  }, [fileRendered])

  function removePassProtected(fileName) {
    let str = fileName + " is password protected"
    ErrorPopup(str)
  }

  useEffect(() => {
    console.log("File: ", file)
  }, [file])

  async function pdfInput(file) {
    const fileReader = new FileReader()
    fileReader.onload = async function () {
      const pdfData = new Uint8Array(this.result)
      await displayPdfSyncPreview(
        pdfData,
        file,
        "canvas-storage",
        1,
        setFileRendered,
        removePassProtected,
        setErrorFiles,
        ErrorPopup,
        setPageNum
      )
    }
    fileReader.readAsArrayBuffer(file)
  }

  const fileTypes = ["pdf"]

  const handleChange = file => {
    setLoading(true)
    setFile(file)
    pdfInput(file)
  }

  const filesSubmit = () => {
    const formData = new FormData()
    formData.append(repository[0].name, repository[0])
    let fileName = repository[0].name

    let data = {
      fileName: fileName,
      formData: formData,
      extension: "png",
      password: window.realPass,
    }
    setDownload(true)
    toPNG(data, setDownload)
  }

  useEffect(() => {
    let size = 0
    repository.forEach(f => {
      size += f.size
    })

    setFilesSize(formatBytes(size, 2))
  }, [repository])

  function ErrorPopup(message) {
    let oldEr = [...errors, message]
    setErrors(er => [...er, message])
    setTimeout(() => {
      if (
        oldEr.length == errors.length &&
        JSON.stringify(oldEr) == JSON.stringify(errors)
      ) {
        setErrors([])
      }
    }, 5000)
  }
  if (download != null) {
    return (
      <DownloadScreen>
        <CompleteIcon />

        {download === true && (
          <DownloadTitle>Your PDF file is being converted to PNG</DownloadTitle>
        )}

        {download !== true && (
          <DownloadTitle>Your PDF file has been converted to PNG</DownloadTitle>
        )}
        <DownloadMessage>
          Your file will automatically download once it's ready. If it doesn't,
          use the button below.
        </DownloadMessage>
        {download === true && (
          <DownloadButton>
            <LoadingCircle />
            Processing...
          </DownloadButton>
        )}
        {download !== true && (
          <DownloadButton onClick={() => download.click()}>
            Download File
          </DownloadButton>
        )}
        <Restart>
          <div className="desktop">Need to convert another file?</div>
          <CtaRestat onClick={restartTool}>
            <RestartIcon /> Start Over{" "}
          </CtaRestat>
        </Restart>
        <UsefulTools />
      </DownloadScreen>
    )
  }

  return (
    <CanvasStyle>
      <ErrorMessageHolder>
        {errors.map(m => {
          return <ErrorMessage message={m}></ErrorMessage>
        })}
      </ErrorMessageHolder>
      {!file && (
        <div className="firstScreen">
          <PNGIcon />
          <h1>PDF to PNG</h1>
          <Title>Easily convert PDFs to PNG for free, online.</Title>
          <FileUploaderStyle>
            <FileUploader
              handleChange={handleChange}
              multiple={false}
              types={fileTypes}
              maxSize={20}
              onSizeError={() => {
                ErrorPopup("Your file needs to be under 20MB size")
              }}
              classes="drop_area drop_zone "
              children={
                <div className="column">
                  <UploadIcon />
                  <div className="title"></div>
                  <div className="info"></div>
                  <button>Upload file</button>
                </div>
              }
            />
          </FileUploaderStyle>
          <Row>
            <Offline>Offline</Offline>
            <ToPdf>
              <div className="desktop">Rather work offline?&nbsp;</div>
              <CtaPopup onClick={openPopup}>
                Try PDF Pro for free today! <ArrowTry />{" "}
              </CtaPopup>
            </ToPdf>
          </Row>

          <Row>
            <Warning />
            <GDPRwarning>
              <Disclaimer /> <PrivacyQuestion />
            </GDPRwarning>
          </Row>
          {myPopUp === "show" && <TryPdf close={closePopup} />}
        </div>
      )}
      <LoadingScreen hide={loading}>
        <Loading>Loading... </Loading>
        <BarBack>
          <BarButton percent={fileRendered} />{" "}
        </BarBack>
        <Percent>{(fileRendered * 100).toFixed(0)}%</Percent>
      </LoadingScreen>
      {file && (
        <SecondScreen>
          <Message id="message">Press Convert to PNG</Message>
          <Pdfpreview ref={pdfPreview} overflowY={pageNum == 1}>
            <Pdfholder id="canvas-storage" ref={pdfHolder}>
              {" "}
            </Pdfholder>
          </Pdfpreview>
          <ButtonHolder>
            <ExportButton onClick={filesSubmit}>
              Convert to PNG
              <ArrowRight />
            </ExportButton>
          </ButtonHolder>
          <Info>
            {file.name}, {pageNum} Pages, {filesSize}
          </Info>
          <RestartSecond>
            <div className="desktop">Need to convert another file?</div>
            <CtaRestat onClick={restartTool}>
              <RestartIcon /> Start Over{" "}
            </CtaRestat>
          </RestartSecond>
        </SecondScreen>
      )}
    </CanvasStyle>
  )
}

export default ToPNGTool
